<template>
<div>
    <Preloader v-if="preloader" />
    
    <v-row>
        <v-col cols="12" md="12">
            <v-card class="formcard materialbb">

                <template>
                    <v-text-field v-model="search" append-icon="mdi-magnify" :label="lang.Search" single-line hide-details class="mySearchInventory"></v-text-field>
                    <v-select v-model="reportType" outlined dense :items="['Overall Report', 'Cumulative Report', 'Item-wise Report']" label="Report" class="rightbutons" @change="OpenReporttype($event)"></v-select>
                    
                    <div  id="ttable" :class="{sticky:activescroll}">
                     <v-slider
                       class="slidermobile"
                        hint="Scroll"
                        max="500"
                        min="0"
                    step="200"
                    ticks="always"
                    thumbColor="#000"
                    color="#f8bb03"
                    tick-size="4"
                    :thumb-size="28"
                     thumb-label="always"
                    @change="moveleft"
                        @end="moveleft"
                          @start="moveleft"
                         
                        >
                            <template v-slot:thumb-label="{ value }">
                                <v-icon :class="value" color="#fff">
                                    mdi-arrow-left-right-bold
                                </v-icon>
                            </template>
                        </v-slider>
                        </div>
                    <v-data-table ref="infoBox" :headers="headers" :search="search" :items="taskdata" :items-per-page="100" :footer-props="footerpro" class="elevation-1 tablemar">

                        <template v-slot:item.actions="{ item }">

                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" class="mr-2 ">
                                        mdi-information-outline
                                    </v-icon>
                                </template>
                                <span>
                                    <h5>{{lang.Remark}} </h5>
                                    <p>{{item.remark }}</p>
                                    <h5>{{lang.Description}} </h5>
                                    <p>{{item.desc}}</p>
                                </span>
                            </v-tooltip>
                            <v-tooltip left v-if="userdata.user_code == 0 || userdata.user_code == 2">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-if="item.verification == 1" v-bind="attrs" v-on="on" class="mr-2 " color="green" @click="verificationEnventory(item)">
                                        mdi-check-circle
                                    </v-icon>
                                    <v-icon v-if="item.verification != 1" v-bind="attrs" v-on="on" class="mr-2 " @click="verificationEnventory(item)">
                                        mdi-check-circle
                                    </v-icon>
                                </template>
                                <span v-if="item.verification == 1">Verified</span>
                                <span v-if="item.verification != 1">Verify</span>
                            </v-tooltip>
                            <v-tooltip left v-else>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-if="item.verification == 1" v-bind="attrs" v-on="on" class="mr-2 " color="green">
                                        mdi-check-circle
                                    </v-icon>
                                    <v-icon v-if="item.verification != 1" v-bind="attrs" v-on="on" class="mr-2 ">
                                        mdi-check-circle
                                    </v-icon>
                                </template>
                                <span v-if="item.verification == 1">Verified</span>
                                <span v-if="item.verification != 1">Verify</span>
                            </v-tooltip>

                            <v-tooltip left v-if="userdata.user_code == 2">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-if="item.editreq == 1" v-bind="attrs" v-on="on" class="mr-2 " color="#f8a103" @click="updateInventory(item)">
                                        mdi-pencil-box-multiple
                                    </v-icon>
                                    <v-icon v-else v-bind="attrs" v-on="on" class="mr-2">
                                        mdi-pencil-box-multiple-outline
                                    </v-icon>
                                </template>
                                <span v-if="item.editreq == 1">
                                    <h5> {{lang.EditRequestNote}}: </h5>
                                    <p>{{item.editnote }}</p>
                                </span>

                                <span v-else>
                                    <h5> {{lang.EditRequestNote}}: </h5>
                                    <p>{{item.editnote }}</p>
                                </span>
                            </v-tooltip>

                            <v-tooltip left v-else>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn :class="userdata.firstname+' '+userdata.lastname == item.UserName ?'show': 'hide'" v-bind="attrs" v-on="on" small :color="item.editreq == '1' ? '#f8bb03' : black" @click="editRequest(item)">
                                        {{lang.EditRequest}}
                                    </v-btn>

                                </template>
                                <span>
                                    <h5> {{lang.EditRequestNote}}: </h5>
                                    <p>{{item.editnote }}</p>
                                </span>
                            </v-tooltip>

                            <v-btn small color="black" dark class="ma-1" @click="Imagess(item.entry_id)">
                                {{lang.Images}}
                            </v-btn>

                        </template>
                        <template v-slot:item.create_at="{ item }">
                            <p>{{ dateFormate(item.create_at) }}</p>
                        </template>

                    </v-data-table>


                    
                          <v-btn small color="black"  v-if="reloadeData" dark class="reloadbuttons" @click="reloadeDataAction" :title="lang.reflected">
                                {{lang.Reload}}
                            </v-btn>

                            
                <table class="table hide" id="inventoryTableDownload"> 

                <thead class="thead-dark"> 
                <tr> 
                <th>Material_Name</th>
                <th>Material_Heading</th>
                <th>Sub_Heading</th>
                <th>DC_No</th>
                <th>PO_No</th>
                <th>Unit</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Asset_Type</th>
                <th>Supplier_Name</th>
                <th>Vehicle_No</th>
                <th>User</th>
                <th>Contact_No</th> 
                <th>Date</th> 
                
                </tr> 
                </thead>
                <tbody>
                    <tr v-for="val in otaskdata" :key="val.id"><td>{{ val.material_name}}</td><td>{{val.material_heading}}</td><td>{{val.sub_heading}}</td><td>{{val.dc_no}}</td><td>{{val.purchase_order_number}}</td><td>{{val.unit}}</td><td>{{val.quantity}}</td><td>{{val.rate}}</td><td>{{val.asset_type}}</td><td>{{val.supplier_name}}</td><td>{{val.vehicle_no}}</td><td>{{val.UserName}}</td><td>{{val.contactNo}}</td><td>{{ dateFormate(val.create_at) }}</td></tr>
                </tbody>
                </table>

                <table class="table hide" id="cumulativeReportDownload">
					
            <thead class="thead-dark"> 
            <tr> 
                <th>Material_Name</th>
				<th>Material_Heading</th>
                <th>Sub_Heading</th>
				<th>Unit</th>
				<th>Quantity</th>
				<th>Rate</th>
				<th>Asset_Type</th>
            </tr> 
        </thead>
		<tbody>
		    <tr v-for="val in cumulative_data" :key="val.id"><td>{{ val.material_name}}</td><td>{{val.material_heading}}</td><td>{{val.sub_heading}}</td><td>{{val.unit}}</td><td>{{val.quantity}}</td><td>{{val.total}}</td><td>{{val.asset_type}}</td></tr>
		</tbody>
    </table>
             

                </template>

            </v-card>
        </v-col>
    </v-row>
    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="sendRequest">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.InventoryEditRequest}} </v-toolbar>
                <v-form ref="editRequest" v-model="valid" lazy-validation @submit.prevent="editRequestSend">

                    <v-container>
                        <v-row>

                            <v-col cols="12" sm="12">
                                <v-textarea v-model="editnote" outlined :label="lang.Commentsforinventoryupdate" required></v-textarea>

                                <v-text-field v-model="superadminEmail" outlined :label="lang.RequestSuperadmin" required :placeholder="lang.Email"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.Send}} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn @click="sendRequest = false" color="mydarkbutton">
                                    {{lang.Cancel}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" max-width="800" color="amber accent-3" v-model="updateInvetory">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.UpdateInventory}} </v-toolbar>
                <v-form ref="formNewTask" v-model="valid" lazy-validation @submit.prevent="submitNewsTask">

                    <v-container>
                        <v-row>
                            <v-col cols="4" sm="4">
                                <v-select required :rules="requerdRules" :items="headings" ref="material_heading" v-model="updateData.material_heading_id" :label="lang.MaterialHeading" item-value='id' item-text='material_heading' @change="getMaterialSubHeading($event)" persistent-hint return-object single-line></v-select>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-select required  :rules="requerdRules" :items="subheadings" v-model="updateData.sub_heading_id" :label="lang.MaterialSubHeading" item-value='id' item-text='subheading' ref="subheading" @change="getMaterialName($event)" persistent-hint return-object single-line></v-select>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-select required :rules="requerdRules" :items="materialnames" v-model="updateData.material_name_id" :label="lang.MaterialName" item-value='id' item-text='materila_name' ref="materila_name" @change="selectMaterial($event)" persistent-hint return-object single-line></v-select>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field  :rules="requerdRules" v-model="updateData.unit" :label="lang.Unit" required readonly></v-text-field>
                            </v-col>

                            <v-col cols="4" sm="4">
                                <v-text-field :rules="requerdRules"  v-model="updateData.dc_no" :label="lang.DCNo" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field  :rules="requerdRules" v-model="updateData.purchase_order_number" :label="lang.PONo" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field  :rules="requerdRules" v-model="updateData.vehicle_no" :label="lang.VehicleNo" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                               <v-select required :rules="requerdRules" :items="suppliers" v-model="updateData.supplier_name" :label="lang.SupplierName" item-value="supplier_name" item-text="supplier_name"></v-select>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field v-model="updateData.desc" :label="lang.Description" required></v-text-field>
                               
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field  :rules="requerdRules" type="number"  v-model="updateData.rate" :label="lang.Rate" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field :rules="requerdRules"  type="number"  v-model="updateData.quantity" :label="lang.Quantity" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field v-model="updateData.remark" :label="lang.Remark"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                   {{ lang.Send }} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn @click="updateInvetory = false" color="mydarkbutton">
                                    {{lang.Cancel}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

    <!-- overallReport  dilog box -->

    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="overallReport">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.OverallReport}} </v-toolbar>
                    <v-btn
                  class="right_aligh"
            icon
            dark
            @click="overallReport = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
                <v-container>
                    <v-row>

                        <v-col cols="12" sm="12" class="center">
                            
                            <download-excel
                        class="btn ma-2 Block--text rightbutons downloadbutons"
                        :data="otaskdata"
                        :fields="json_fields"
                        :title="project_name"
                        worksheet="Tasks"
                        :name="`${project_name}.xls`"
                        :before-finish = "finishDownload"
                        >
                        {{lang.DownloadExcel}}  <v-icon right dark color="#000">
                        mdi-file-excel
                        </v-icon>         
                        </download-excel> 

                         {{lang.OR}}

                         <v-btn @click="DownloadOverallPDF"  color="#ffc400" class="btn ma-2 Block--text rightbutons downloadbutons"> 
                               {{lang.DownloadPDF}}
                                 <v-icon right dark color="#000">
                        mdi-file-pdf
                        </v-icon>        
                         </v-btn>

                        </v-col>
                    </v-row>
                </v-container>

            </v-card>
        </template>
    </v-dialog>

     <!-- overallReport  dilog box -->

    <v-dialog transition="dialog-bottom-transition" max-width="800"  color="amber accent-3" v-model="cumulativeReport">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.CumulativeReport}} </v-toolbar>
                      <v-btn
                  class="right_aligh"
            icon
            dark
            @click="cumulativeReport = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
                <v-container>
                    <lable class="ma-4" >{{lang.SelectDate}}</lable>    
                    <div class="cumulativebox2" > 
                         
                  <date-range-picker
    v-model="dateRange"
    :date-format="dateFormat"
      class="v-text-field__slot"
     @update="updateValues"
          
  ></date-range-picker>

        <v-row>

                        <v-col cols="12" sm="12" class="center" mt="100">
                            
                            <download-excel
                        class="btn ma-2 Block--text rightbutons downloadbutons"
                        :data="cumulative_data"
                        :fields="cumulative_json_fields"
                        :title="title"
                        worksheet="Tasks"
                        :name="`${filename}.xls`"
                        :before-finish = "finishDownload"
                        >
                        {{lang.DownloadExcel}}  <v-icon right dark color="#000">
                        mdi-file-excel
                        </v-icon>         
                        </download-excel> 

                         {{lang.OR}}

                         <v-btn @click="cumulativeDownloadPDF()"  color="#ffc400" class="btn ma-2 Block--text rightbutons downloadbutons"> 
                               {{lang.DownloadPDF}}
                                 <v-icon right dark color="#000">
                        mdi-file-pdf
                        </v-icon>        
                         </v-btn>

                        </v-col>
                    </v-row>
  </div>
                </v-container>

            </v-card>
        </template>
    </v-dialog>

     <!-- overallReport  dilog box -->

    <v-dialog transition="dialog-bottom-transition" max-width="800" color="amber accent-3" v-model="materialwiseReport">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.MaterialwiseReport}} </v-toolbar>
                  <v-btn
                  class="right_aligh"
            icon
            dark
            @click="materialwiseReport = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
                <v-container>

                <v-radio-group
                v-model="reportby"
                row
                >
                <v-radio
                label="Material Name"
                value="Material Name"
                ></v-radio>
                <v-radio
                label="Supplier Name"
                value="Supplier Name"
                ></v-radio>
                </v-radio-group>
                    <v-row>
                        <v-col cols="12" sm="12">
                        <!-- <v-select
                        :items="materialList"
                        label="Materials"
                        v-if="reportby == 'Material Name'"
                        v-model="selecteMaterial"
                        ></v-select> -->
                        <v-autocomplete
                        :items="materialList"
                        label="Materials"
                        v-if="reportby == 'Material Name'"
                        v-model="selecteMaterial"></v-autocomplete>

                        <!-- <v-select
                        :items="supplierList"
                        label="Suppliers"
                        v-if="reportby == 'Supplier Name'"
                        v-model="selectedSupplier"
                        ></v-select> -->
                         <v-autocomplete
                         :items="supplierList"
                        label="Suppliers"
                        v-if="reportby == 'Supplier Name'"
                        v-model="selectedSupplier"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <lable class=" ma-4">Select Dates</lable>    
                     <div class="cumulativebox" > 
                     
                  <date-range-picker
    v-model="dateRangeitemwise"
    :date-format="dateFormat"
     class="v-text-field__slot"
     @update="updateValuesItemwise"
          
  ></date-range-picker>

        <v-row>

                        <v-col cols="12" sm="12" class="center" mt="100">
                            
                            <download-excel
                        class="btn ma-2 Block--text rightbutons downloadbutons"
                        :data="otaskdata"
                        :fields="json_fields"
                        :title="title"
                        worksheet="Tasks"
                        :name="`${filename}.xls`"
                        :before-finish = "finishDownload"
                        >
                        {{lang.DownloadExcel}}  <v-icon right dark color="#000">
                        mdi-file-excel
                        </v-icon>         
                        </download-excel> 

                         {{lang.OR}}

                         <v-btn @click="DownloadOverallPDF()"  color="#ffc400" class="btn ma-2 Block--text rightbutons downloadbutons"> 
                               {{lang.DownloadPDF}}
                                 <v-icon right dark color="#000">
                        mdi-file-pdf
                        </v-icon>        
                         </v-btn>

                        </v-col>
                    </v-row>
  </div>
              
                </v-container>

            </v-card>
        </template>
    </v-dialog>

    <v-snackbar v-model="alert" top right timeout="3000" :type="alertType">
        {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>
 
 <div class="imagespopup" v-if="imagesshow"> 
     <v-row>
          
        <v-col cols="12" md="12">
            <v-card class="pa-5 formcard">

                  <template>
                   
                      <v-btn
      class="ma-2"
     color="mylightbutton"
     @click="imagesshow = false"
    >
      {{lang.goback}}
       <v-icon right>mdi-undo-variant</v-icon>
        
    </v-btn>

                      <h3 v-if="vei.length > 0"> {{lang.VehicleEntry}} </h3>
                    <v-row v-if="vei.length > 0">

                       <v-col cols="4" md="4" v-for="i in vei"  :key="i.id">
                               <img :src="i.imagepath" />

                               <p> {{getDistanceFromLatLonInKm(latitude, longitude, i.lat, i.long,)}}M {{lang.offtheproject }}. | {{dateFormate(i.create_at)}} | <a :href="i.imagepath">{{lang.DownloadImage}}</a> </p>
                       </v-col>
                   </v-row>       

                   <h3 v-if="dci.length > 0"> {{lang.deliverychalaimage}} </h3>
                    <v-row v-if="dci.length > 0">

                       <v-col cols="4" md="4" v-for="i in dci"  :key="i.id">
                               <img :src="i.imagepath" />

                               <p> {{getDistanceFromLatLonInKm(latitude, longitude, i.lat, i.long,)}}M {{lang.offtheproject }}. | {{dateFormate(i.create_at)}} | <a :href="i.imagepath">{{lang.DownloadImage}}</a> </p>
                       </v-col>
                   </v-row>      

                   <h3 v-if="vnpi.length > 0"> {{lang.VehicleNumberPlate}} </h3>
                    <v-row v-if="vnpi.length > 0">

                       <v-col cols="4" md="4" v-for="i in vnpi"  :key="i.id">
                               <img :src="i.imagepath" />

                               <p> {{getDistanceFromLatLonInKm(latitude, longitude, i.lat, i.long,)}}M {{lang.offtheproject }}. | {{dateFormate(i.create_at)}} | <a :href="i.imagepath">{{lang.DownloadImage}}</a> </p>
                       </v-col>
                   </v-row>      
                   <h3 v-if="vui.length > 0"> {{lang.vehivleunloading}} </h3>
                    <v-row v-if="vui.length > 0">

                       <v-col cols="4" md="4" v-for="i in vui"  :key="i.id">
                               <img :src="i.imagepath" />

                               <p> {{getDistanceFromLatLonInKm(latitude, longitude, i.lat, i.long,)}}M {{lang.offtheproject }}. | {{dateFormate(i.create_at)}} | <a :href="i.imagepath">{{lang.DownloadImage}}</a> </p>
                       </v-col>
                   </v-row>      
                   <h3 v-if="vbi.length > 0"> {{lang.VehicleEmpty}} </h3>
                    <v-row v-if="vbi.length > 0">

                       <v-col cols="4" md="4" v-for="i in vbi"  :key="i.id">
                               <img :src="i.imagepath" />

                               <p> {{getDistanceFromLatLonInKm(latitude, longitude, i.lat, i.long,)}}M {{lang.offtheproject }}. | {{dateFormate(i.create_at)}} | <a :href="i.imagepath">{{lang.DownloadImage}}</a> </p>
                       </v-col>
                   </v-row>      
                   <h3 v-if="mi.length > 0"> {{lang.other}} </h3>
                    <v-row v-if="mi.length > 0">

                       <v-col cols="4" md="4" v-for="i in mi"  :key="i.id">
                               <img :src="i.imagepath" />

                               <p> {{getDistanceFromLatLonInKm(latitude, longitude, i.lat, i.long,)}}M {{lang.offtheproject }}. | {{dateFormate(i.create_at)}} | <a :href="i.imagepath">{{lang.DownloadImage}}</a> </p>
                       </v-col>
                   </v-row>      
                </template>

            </v-card>
        </v-col>
    </v-row>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'
import JsonExcel from "vue-json-excel";
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import DateRangePicker from 'vue2-daterange-picker'
import { locals } from "../../utils/locals";
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
    components: {
        Preloader,
        "downloadExcel" : JsonExcel,
        DateRangePicker
    },

    computed: {


       
    },
    name: "EditTask",
    data() {
        return {
            requerdRules: [
            v => !!v || this.lang.required,
            ],
             numberOnlyRules: [
                v => !!v || this.lang.required,
                v => /^[0-9]*[1-9][0-9]*$/.test(v) || this.lang.integetrequre,
            ],
            alert: false,
            updateInvetory: false,
            alertmessage: '',
            alertType: "error",
            preloader: false,
            project_name: 'ProjectName',
            projectid: 0,
            search: '',
            taskdata: [],
            otaskdata :[],
            userdata: {},
            user_material_access:false,
            headers: [{
                    text: 'Material Name ',
                    align: 'start',
                    value: 'material_name',
                    width: 120,
                     sortable: false,
                },
                {
                    text: 'Material Heading',
                    value: 'material_heading',
                    width: 120,
                     sortable: false,
                },
                {
                    text: 'Sub Heading',
                    value: 'sub_heading',
                    width: 120,
                     sortable: false,
                },
                {
                    text: 'DC No',
                    value: 'dc_no',
                    width: 40,
                     sortable: false,
                },
                {
                    text: 'PO No ',
                    value: 'purchase_order_number',
                    width: 80,
                     sortable: false,
                },
                {
                    text: 'Unit',
                    value: 'unit',
                    width: 70,
                     sortable: false,
                },
                {
                    text: 'Qty',
                    value: 'quantity',
                    width: 70,
                     sortable: false,
                },
                {
                    text: 'Rate',
                    value: 'rate',
                    width: 70,
                     sortable: false,
                },
                {
                    text: 'Asset Type',
                    value: 'asset_type',
                    width: 100,
                     sortable: false,
                },
                {
                    text: 'Supplier',
                    value: 'supplier_name',
                    width: 100,
                     sortable: false,
                },
                {
                    text: 'Vehicle No.',
                    value: 'vehicle_no',
                    width: 100,
                     sortable: false,
                },
                {
                    text: 'User',
                    value: 'UserName',
                    width: 60,
                     sortable: false,
                },
                
                {
                    text: 'Date',
                    value: 'create_at',
                    width: 60,
                     sortable: false,
                },
                {
                    text: 'Contact No',
                    value: 'contactNo',
                    width: 100,
                     sortable: false,
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    width: 250,
                },
                 {
                    text: 'Entry ID',
                    value: 'entry_id',
                    sortable: true,
                    width: 250,
                }
                
            ],

            json_fields: {
                    'Material Name' : 'material_name',
                    'Material Heading': 'material_heading',
                    'Sub Heading' : 'sub_heading',
                    'DC No':'dc_no',
                    'PO No ' :'purchase_order_number',
                    Unit:'unit',
                    Quantity: 'quantity',
                    Rate:'rate',
                    'Asset Type' :'asset_type',
                    'Supplier Name':'supplier_name',
                    'Vehicle No.':'vehicle_no',
                    User:'UserName',
                    'Contact No':'contactNo',
                      Date: {
        field: 'create_at',
        callback: (value) => {
          return this.dateFormate(value);
        },
      },
                },

                cumulative_json_fields : {
                    'Material Name' : 'material_name',
                    'Material Heading': 'material_heading',
                    'Sub Heading' : 'sub_heading',
                    Unit:'unit',
                    Quantity: 'quantity',
                     Rate:'rate',
                    'Asset Type' :'asset_type',
                    
                   },
            
            json_meta: [
            [
                {
                key: "charset",
                value: "utf-8",
                },
            ],
            ],
            footerpro: {
                itemsPerPageOptions: [100, 250, 500, -1],
            },
            updateData: [],
            headings: [],
            subheadings: [],
            materialnames: [],

            send: false,
            valid: false,
            sendRequest: false,
            superadminEmail: '',
            editnote: '',
            editRequestdata: {},
            reportType: '',
            overallReport: false,
            cumulativeReport: false,
            materialwiseReport: false,
             dateRange: {},
         cumulative_data :{},
         filename : '',
         title : '',
         materialList : [],
         supplierList : [],
         reportby : 'Material Name',
         selectedSupplier : '',
         selecteMaterial : '',
         dateRangeitemwise : {},
         suppliers : {},
         count : 0,
         scroller : 10,
          satisfactionEmojis: [],
          reloadeData :  false,
          activescroll: false,
          Local :locals,  
         lang : {},
            vei : [],
            dci : [],
            vnpi : [],
            vui : [],
            vbi : [],
            mi : [],
            latitude :  '24.7498085',
            longitude : '78.8139369',
            imagesshow : false
        };
    },
    mounted() {

        var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng];

        window.document.onscroll = () => {
      let navBar = document.getElementById('ttable');
      if(window.scrollY > navBar.offsetTop){
        this.activescroll = true;
        } else {
        this.activescroll = false;
      }
    }

   
  var _self  = this;
         setTimeout(() => {
                  
        _self.userdata = JSON.parse(localStorage.getItem('userdata'));
        
        if (_self.userdata.material_access == 1) {
            _self.user_material_access = true
        }else{
                 _self.$router.push('/');
        }
         if(_self.userdata.free_access == 0){
          _self.useraccess = true
        }else{
          _self.$router.push('/');
        }
           _self.initViewer();

        }, 4000);
    },
    watch: {
        '$store.state.currentCamera': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            this.projectid = currentProject.id;
            this.project_name = currentProject.project_name;
            this.title = currentProject.project_name;
             this.latitude = currentProject.latitude;
              this.longitude = currentProject.longitude;

            if(this.count > 0){

            this.getTaskData();
            this.getSupplier();
            }
        }
    },

    methods: {
        validate() {
            this.$refs.form.validate()
        },
        reloadeDataAction(){
             
              // this.preloader = true;
            
             let currentProject = JSON.parse(localStorage.getItem('currentProject'));

            var _self = this;

          if(currentProject === null){
                  _self.reloadeData = true;
          }else{
                _self.reloadeData = false;
          }
            console.log("projectdata==>", currentProject === null);
        _self.projectid = currentProject.id;
        _self.project_name = currentProject.project_name;
        _self.title = currentProject.project_name;
       
       
        if(currentProject.material == 1){
          _self.user_material_access = true;
        }else{
              _self.$router.push('/');
        }
           _self.getSupplier();
            this.getTaskData();

        },
        initViewer() {
            // console.log("init Viewer");
           // this.preloader = true;
            
             let currentProject = JSON.parse(localStorage.getItem('currentProject'));

            var _self = this;

          if(currentProject === null){
             _self.reloadeData = true;
          }else{
            _self.reloadeData = false; 
          }
          
        _self.projectid = currentProject.id;
        _self.project_name = currentProject.project_name;
        _self.title = currentProject.project_name;

         _self.latitude = currentProject.latitude;
          _self.longitude = currentProject.longitude;
       
       
        if(currentProject.material == 1){
          _self.user_material_access = true;
        }else{
              _self.$router.push('/');
        }
           _self.getSupplier();
            this.getTaskData();
            
        },
         moveleft(c){

            // console.log(c)
            let content = document.querySelector(".v-data-table__wrapper");
                    content.scrollLeft  = c;
         },
            
        getTaskData() {
            this.taskdata = [];
            this.otaskdata = [];
              this.materialList = [];
                this.supplierList = [];
            this.preloader = true;
            // var projectid = '372';
            var filter = '{"where": {"and": [{ "projectid": "' + this.projectid + '"},{"status": 1}]},"order": "id DESC"}';
            var urlfilter = encodeURI(filter);
            axios({
                    url: 'material/get_enventory',
                    method: 'get',
                    params: {
                        f: urlfilter
                    }
                })
                .then(resp => {
                    this.preloader = false;
                    //console.log("Resp", resp);
                    this.taskdata = resp.data;
                    
                    

                        var rate = 1;

                //var qty = 0;
                var grandtotal = 0;

                      resp.data.forEach(val => {

                            
                           if(!this.materialList.includes(val.material_name)){
                                  
                                  this.materialList.push(val.material_name);

                           }
                           if(!this.supplierList.includes(val.supplier_name)){
                               this.supplierList.push(val.supplier_name);
                           }

                            if(val.rate == ''){
                             rate = 0;
                            }
                            //qty += parseInt(val.quantity);

                           // console.log("qty =>", qty,parseInt(val.quantity));

                             grandtotal += parseFloat(val.quantity )* parseFloat(val.rate);
		                   //  var n = new Date(val.create_at).toLocaleString('en-GB', {timeZone: 'Asia/Kolkata'});
                            

                      });
                    var pdata = resp.data;

                   
                     var gtt = '';
                     
                      
                  if(rate == 1){
                            
                       gtt = grandtotal;
                    
                     pdata.push({
                    UserName: "",
                    asset_type: "",
                    contactNo: "",
                    create_at: "",
                    dc_no: "",
                    desc: "",
                    entry_id: "",
                    id: "",
                    material_heading: "",
                    material_heading_id: "",
                    material_name: "",
                    material_name_id: "",
                    projectid: "",
                    purchase_order_number: "",
                    quantity: "",
                    rate: gtt,
                    remark: "",
                    status: "",
                    sub_heading: "",
                    sub_heading_id: "",
                    supplier_name: "",
                    unit: "",
                    vehicle_no: ""
                     })
                  }


                     this.otaskdata = pdata;

                  //   console.log(this.otaskdata);
                      var _self  = this;
                      setTimeout(() => {
                        _self.count++;
                       // _self.matchHeight();
                    }, 2000);
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        dateFormate(d) {

            var n = new Date(d).toLocaleString('en-GB', {
                timeZone: 'Asia/Kolkata'
            });

            return n;
        },

         titledateFormate(d) {

            var n = new Date(d).toLocaleDateString('en-GB', {
                timeZone: 'Asia/Kolkata'
            });

            return n;
        },

        verificationEnventory(item) {
            this.preloader = true;

            var varify = 1;

            var m = "Entry marked as verified.";

            if (item.verification == 1) {
                varify = 0;
                m = "Entry status not verified.";
            }

            axios({
                    url: '/material/inventoryVerifiaciation',
                    method: 'get',
                    params: {
                        verification: varify,
                        updateid: item.id
                    }
                })
                .then(() => {
                    //console.log("Resp", resp);
                    this.alert = true;
                    this.alertmessage = m;
                    this.alertType = "success";
                    var _self = this;
                    this.getTaskData()
                    setTimeout(() => {
                        _self.preloader = false;
                      
                    }, 2000);
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },

        updateInventory(item) {
            this.getMaterialHeading();
            //console.log(item);
            this.updateData = item;
            this.updateInvetory = true;

            //console.log(this.updateData);

        },
        getMaterialHeading() {

            var projectid = this.projectid;
            var mhfilter = '{"where": {"projectid":' + projectid + '}}';
            var urlfilter = encodeURI(mhfilter);
            axios({
                    url: 'material/heading',
                    method: 'get',
                    params: {
                        f: urlfilter
                    }
                })
                .then(resp => {
                    console.log("headings", resp);
                    this.headings = JSON.parse(resp.data.success);
                    this.getMaterialSubHeading();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        getMaterialSubHeading() {

            // var optionText = event.target.options[event.target.options.selectedIndex].text;

            //console.log(optionText);
           // console.log(this.updateData.material_heading_id);
            var id;
            if (this.updateData.material_heading_id.id) {
                id = this.updateData.material_heading_id.id;
                this.updateData.material_heading = this.updateData.material_heading_id.material_heading;

            } else {
                id = this.updateData.material_heading_id;
            }

            var mhfilter = '{"where": { "material_heading_id": "' + id + '"}}';
            var mhurlfilter = encodeURI(mhfilter);
            axios({
                    url: 'material/subheading',
                    method: 'get',
                    params: {
                        f: mhurlfilter
                    }
                })
                .then(resp => {
                   // console.log("headings", resp);
                    this.subheadings =   JSON.parse(resp.data.success);
                    this.getMaterialName();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error";
                })
        },
        getMaterialName() {
            //   var id = this.updateData.sub_heading_id;

          //  console.log(this.updateData.sub_heading_id);
            var id;
            if (this.updateData.sub_heading_id.id) {
                id = this.updateData.sub_heading_id.id;
                this.updateData.sub_heading = this.updateData.sub_heading_id.subheading;

            } else {
                id = this.updateData.sub_heading_id;
            }

            var mhfilter = '{"where": { "subheading_id": "' + id + '"}}';
            var mhurlfilter = encodeURI(mhfilter);
            axios({
                    url: 'material/materials',
                    method: 'get',
                    params: {
                        f: mhurlfilter
                    }
                })
                .then(resp => {
                  //  console.log("materials", resp);
                    this.materialnames = JSON.parse(resp.data.success);
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        selectMaterial() {
            //var id = this.updateData.material_name_id;

            var id;
            if (this.updateData.material_name_id.id) {
                id = this.updateData.material_name_id.id;
                this.updateData.material_name = this.updateData.material_name_id.materila_name;

            } else {
                id = this.updateData.material_name_id;
            }
           // console.log("materialid", id)
            axios({
                    url: 'material/unitmaterial',
                    method: 'get',
                    params: {
                        materialid: id
                    },
                })
                .then(resp => {
                   // console.log("materialsUnit", resp);
                    this.updateData.unit = resp.data.unit;
                    this.updateData.asset_type = resp.data.asset_type;
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        submitNewsTask() {
            this.send = true;
            var data = this.updateData;

            if (data.editreq == 1) {
                data.editreq = 0;
                data.editnote = data.editnote + "-- Update by " + this.userdata.firstname + " " + this.userdata.lastname;
            }

            data.update = 1;
            data.status = 1;

           // console.log("form data", data);

            axios({
                    url: 'material/newinventory',
                    method: 'get',
                    params: data,
                })
                .then(() => {
                    //console.log("materialsUnit", resp);
                    this.send = false;
                    this.updateInvetory = false;

                    this.alert = true;
                    this.alertmessage = this.laang.InventoryUpdateSuccessfully;
                    this.alertType = "success";
                    this.getMaterialName()
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error";
                })
        },
        editRequestSend() {

           // console.log(this.editRequestdata)

            var rdata = new Date(this.editRequestdata.create_at).toLocaleString('en-GB', {
                timeZone: 'Asia/Kolkata'
            });

            var data = {
                editnote: this.editnote,
                updateid: this.editRequestdata.id,
                editreq: 1,
                notifyemail: this.superadminEmail,
                sentby: this.userdata.firstname + " " + this.userdata.lastname,
                sentemail: this.userdata.email,
                projectname: this.editRequestdata.projectname,

                rmaterial: this.editRequestdata.material_name,
                rheading: this.editRequestdata.material_heading,
                rsubheading: this.editRequestdata.sub_heading,
                rdate: rdata,
                delivety_chalan: this.editRequestdata.dc_no,
            }

           // console.log(data);
            axios({
                    url: 'material/editRequest',
                    method: 'get',
                    params: data,
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                    this.send = false;
                    this.sendRequest = false;

                    this.alert = true;
                    this.alertmessage = this.lang.Requestsuccessfullysent;
                    this.alertType = "success";
                    // this.getMaterialName()
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error";
                })
        },
        editRequest(item) {
           // console.log(item);
            this.sendRequest = true;
            this.editRequestdata = item;
        },
        Imagess(id) { 
            this.preloader = true;
            // this.$router.push({
            //     name: 'material_inventoryImages',
            //     params: {
            //         id: id
            //     }
            // });

             var entery_id = id;

            this.vei = [];
            this.dci = [];
            this.vnpi = [];
            this.vui = [];
            this.vbi = [];
            this.mi = [];
           

            axios({
                    url: 'material/inventory/images',
                    method: 'get',
                    params : { enteryid : entery_id}
                })
                .then(resp => {
                     console.log("headings",resp);

                      this.imagesshow = true;
                        this.preloader = false;

                      resp.data.forEach(item => {
                             
                       
                    var url = item.imagepath;
           var s = url.split("001/");

                var s1 = s[1];

                 console.log(s1);

                          var p = {
                                  "key" : s1,
                                  "exp" : "30 minutes",
                                  "project_id" : this.projectid
                                  }
           axios({
                    url: '/get_sign_url_by_key',
                    params : p,
                    method: 'GET'
                })
                .then(res => {

                     console.log(res.data.success.url);

                      item.imagepath = res.data.success.url;

                     //return "https://s3-ap-southeast-1.amazonaws.com/aws.opticvyu.com.001/PRJCT372/Inv/vei/Img_1624863593123.jpg?X-Amz-Content-Sha256=e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASKGTOTLMJMGHFLXM%2F20210701%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20210701T162921Z&X-Amz-SignedHeaders=Host&X-Amz-Expires=172800&X-Amz-Signature=1a1da14fc5fa9a5088017b515fb053e145dbac615533143d3bbe01f13db6ec7b";
                      if(item.dc_no == "vei"){
                                 this.vei.push(item);
                         }

                         if(item.dc_no == "dci"){
                                 this.dci.push(item);
                         }

                         if(item.dc_no == "vnpi"){
                                 this.vnpi.push(item);
                         }
                         
                         if(item.dc_no == "vui"){
                                 this.vui.push(item);
                         }

                         if(item.dc_no == "vbi"){
                                 this.vbi.push(item);
                         }

                         if(item.dc_no == "mi"){
                                 this.mi.push(item);
                         }
             
             })    
                    });

                    console.log(this.vei);
                      
                })
                .catch(err => {
                     console.log(err);
                     this.alert = true;
                    this.alertmessage = "Something is went wrong please try again.";
                    this.alertType = "error";
                })
        },
        OpenReporttype(value) {
          //  console.log(value);

            if (value == "Overall Report") {
                this.overallReport = true;
                this.cumulativeReport = false;
                this.materialwiseReport = false;
            }
            if (value == "Cumulative Report") {
                this.overallReport = false;
                this.cumulativeReport = true;
                this.materialwiseReport = false;
            }
            if (value == "Item-wise Report") {
                this.overallReport = false;
                this.cumulativeReport = false;
                this.materialwiseReport = true;
            }
        },
           DownloadOverallPDF(){
               var doc = new jsPDF('p', 'mm', 'a1');
                
                  doc.text(300, 9, this.title); 
                  doc.text(20, 9, "OpticVyu"); 
                  doc.text(550, 9, this.titledateFormate(new Date())); 
                doc.autoTable({ html: '#inventoryTableDownload', startY: 15,  
        theme: 'grid'})
                // doc.addPage("a1", "portrait")
                 doc.save(this.project_name+'.pdf');
                  this.preloader = false;
            this.overallReport = false;
            this.cumulativeReport = false;
            this.materialwiseReport = false;
            this.dateRange = {};
            this.dateRangeitemwise = {};
            this.otaskdata = {};
            this.cumulative_data = {};
           var _self = this;
            setTimeout(() => {
             _self.$router.go();
            },7000);

           },
            dateFormat (classes) {
               //  console.log(classes);
        // if (!classes.disabled) {
        //   classes.disabled = date.getTime() < new Date()
        // }
        return classes
      },
      updateValues(){
           console.log("CumulativeDownloadPDF", this.dateRange.startDate, this.dateRange.endDate)
            var startdata = new Date(this.dateRange.startDate).toISOString();
	    var enddata = new Date(this.dateRange.endDate).toISOString();
	 
	//  console.log("startdata",startdata);
	//  console.log("enddata",enddata);


       this.filename = this.project_name+"_"+startdata+"_"+enddata;
       this.title = this.project_name+" - "+this.titledateFormate(startdata)+" to "+this.titledateFormate(enddata);
     
            this.preloader = true;
            // var projectid = '372';
           // var filter = '{"where": {"and": [{ "projectid": "' + this.projectid + '"},{"status": 1}]},"order": "id DESC"}';
            var filter = '{"where":{"and": [{ "projectid": "'+ this.projectid +'"},{"status": 1},{"create_at": {"between": ["'+startdata+'","'+enddata+'"] }}]},"order": "id DESC"}';
             
             console.log(filter);
          
            var urlfilter = encodeURI(filter);
            axios({
                    url: 'material/get_enventory',
                    method: 'get',
                    params: {
                        f: urlfilter
                    }
                })
                .then(resp => {
                    this.preloader = false;
                  //  console.log("Resp", resp);
                   // this.taskdata = resp.data;

                     var rate = 1;
		  
		  
			let output = resp.data.reduce((op,cur)=>{
			if(op[cur['material_name']]){
				  if(cur['rate'] == ''){
					  rate = 0; 
				  }
			op[cur['material_name']]['quantity']+=parseInt(cur['quantity']);
			op[cur['material_name']]['material_heading']=cur['material_heading'];
			op[cur['material_name']]['material_name']=cur['material_name'];
			op[cur['material_name']]['sub_heading']=cur['sub_heading'];
			op[cur['material_name']]['rate']=cur['rate'];
			op[cur['material_name']]['total']+=parseFloat(cur['rate'])*parseFloat(cur['quantity']);
			op[cur['material_name']]['asset_type']=cur['asset_type'];
			op[cur['material_name']]['unit']=cur['unit'];
			op[cur['material_name']]['COUNT']++;
			} else {
				  if(cur['rate'] == ''){
					  rate = 0; 
				  }
			op[cur['material_name']] ={ 
			'COUNT' : 1, 
			'quantity' : parseInt(cur['quantity']),
			'material_heading' : cur['material_heading'],
			'material_name' : cur['material_name'],
			'sub_heading' : cur['sub_heading'],
			'rate' : cur['rate'],
			'asset_type' : cur['asset_type'],
			'total' : parseFloat(cur['rate'])*parseFloat(cur['quantity']),
			'unit' : cur['unit'],
			 
			}
			} 
			return op;
			},{}) 
			console.log(output);
		    var grandTotal = 0;

             console.log(grandTotal);
             console.log(rate);
                      
               // this.cumulative_data =  output;

                var a = [];
                            
                            
                                for (var prop in output) {
                                        
                                         grandTotal += output[prop].total;
                                     a.push({
                                        
                                        'quantity' : output[prop].quantity,
                                        'material_heading' :  output[prop].material_heading,
                                        'material_name' :  output[prop].material_name,
                                        'sub_heading' :  output[prop].sub_heading,
                                        'rate' :  output[prop].rate,
                                        'asset_type' :  output[prop].asset_type,
                                        'total' :  output[prop].total,
                                        'unit' :  output[prop].unit
                                     })   
                                      
                                       
                                }


                        a.push({
                             'COUNT' : '', 
                        'quantity' : 'Grand Total: ',
                        'material_heading' : '',
                        'material_name' : '',
                        'sub_heading' : '',
                        'rate' : '',
                        'asset_type' : '',
                        'total' : grandTotal,
                        'unit' : ''
                        })  

                      this.cumulative_data  = a;  
  
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
            
      },
      cumulativeDownloadPDF(){
          var doc = new jsPDF('p', 'mm', 'a1');
              doc.text(300, 9, this.title); 
                  doc.text(20, 9, "OpticVyu"); 
                  doc.text(550, 9, this.titledateFormate(new Date())); 
               
              doc.autoTable({ html: '#cumulativeReportDownload',startY: 15,  
        theme: 'grid' })
                // doc.addPage("a2", "portrait")
                doc.save(this.filename+'.pdf');

                 this.preloader = false;
            this.overallReport = false;
            this.cumulativeReport = false;
            this.materialwiseReport = false;
             this.dateRange = {};
            this.dateRangeitemwise = {};
            this.otaskdata = {};
            this.cumulative_data = {};
             var _self = this;
            setTimeout(() => {
             _self.$router.go();
            },7000);
      },
       finishDownload(){
             this.preloader = false;
            this.overallReport = false;
            this.cumulativeReport = false;
            this.materialwiseReport = false;

            this.dateRange = {};
            this.dateRangeitemwise ={};
            var _self = this;
            setTimeout(() => {
             _self.$router.go();
            },7000);
           },
        getSupplier(){
            this.preloader = true;
                 var projectid = this.projectid;
                //  var filter = '{"where":{ "project_id": '+projectid+'}}';
                //  var urlfilter = encodeURI(filter);
        
             axios({
                    url: '/material/suppliers',
                    method: 'get',
                    params: {f:projectid}
              
              })
                .then(resp => {
                    console.log("Suppliers", resp);
                     this.preloader = false;
                     this.suppliers = resp.data;
                   
                })
                .catch(err => {
                    console.log(err);
                     this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },  
      updateValuesItemwise(){
            
          //  console.log("CumulativeDownloadPDF", this.dateRangeitemwise.startDate, this.dateRangeitemwise.endDate)
            var startdata = new Date(this.dateRangeitemwise.startDate).toISOString();
	    var enddata = new Date(this.dateRangeitemwise.endDate).toISOString();
	 
	//  console.log("startdata",startdata);
	 // console.log("enddata",enddata);


       this.filename = this.project_name+"_"+startdata+"_"+enddata;
       

     
            this.preloader = true;
            // var projectid = '372';
           // var filter = '{"where": {"and": [{ "projectid": "' + this.projectid + '"},{"status": 1}]},"order": "id DESC"}';
            var filter;
            
                if(this.reportby == "Material Name"){
                var materialName =  this.selecteMaterial;
                this.title = this.project_name+" - "+materialName+" - "+this.titledateFormate(startdata)+" to "+this.titledateFormate(enddata);
                filter = '{"where":{"and": [{ "projectid": "'+this.projectid+'"},{"status": 1},{"create_at": {"between": ["'+startdata+'","'+enddata+'"] }},{"material_name":"'+materialName+'"}]},"order": "id DESC"}';
                }else{
                var supplierName =  this.selectedSupplier;
                this.title = this.project_name+" - "+supplierName+" - "+this.titledateFormate(startdata)+" to "+this.titledateFormate(enddata);
                console.log(supplierName);
                filter = '{"where":{"and": [{ "projectid": "'+this.projectid+'"},{"status": 1},{"create_at": {"between": ["'+startdata+'","'+enddata+'"] }},{"supplier_name":"'+supplierName+'"}]},"order": "id DESC"}';
                }

               
             
           //  console.log(filter);



            var urlfilter = encodeURI(filter);
            axios({
                    url: 'material/get_enventory',
                    method: 'get',
                    params: {
                        f: urlfilter
                    }
                })
                .then(resp => {
                     
                     this.preloader = false;
                   // console.log("itemwise data", resp);

                var rate = 1;

                var qty = 0;
                var grandtotal = 0;

                      resp.data.forEach(val => {
                            if(val.rate == ''){
                             rate = 0;
                            }
                            qty += parseInt(val.quantity);

                            //console.log("qty =>", qty,parseInt(val.quantity));

                             grandtotal += parseFloat(val.quantity )* parseFloat(val.rate);
		                   //  var n = new Date(val.create_at).toLocaleString('en-GB', {timeZone: 'Asia/Kolkata'});
                            

                      });
                    var pdata = resp.data;

                     var qt = '';
                     var gtt = '';
                     
                      
                  if(rate == 1){
                     if(this.reportby == "Material Name"){
                            
                            qt = qty;
                            gtt = grandtotal;

                     }else{
                               gtt = grandtotal;
                     }
                  }else{
                       if(this.reportby == "Material Name"){
                         qt = qty;
                       }
                  }

                     pdata.push({
                    UserName: "",
                    asset_type: "",
                    contactNo: "",
                    create_at: "",
                    dc_no: "",
                    desc: "",
                    entry_id: "",
                    id: "",
                    material_heading: "",
                    material_heading_id: "",
                    material_name: "",
                    material_name_id: "",
                    projectid: "",
                    purchase_order_number: "",
                    quantity: qt,
                    rate: gtt,
                    remark: "",
                    status: "",
                    sub_heading: "",
                    sub_heading_id: "",
                    supplier_name: "",
                    unit: "",
                    vehicle_no: ""
                     })


                     this.otaskdata = pdata;

                  //   console.log(this.otaskdata);

  
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
            

      },
            getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
            var R = 6371; // Radius of the earth in km
            var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
            var dLon = this.deg2rad(lon2-lon1); 
            var a = 
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
            Math.sin(dLon/2) * Math.sin(dLon/2)
            ; 
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
            var d = (R * c); // Distance in km
             var v = d.toFixed(2);
             console.log(lat1, lon1, lat2, lon2);
             console.log(v)
            return v;
            },

    deg2rad(deg) {
    return deg * (Math.PI/180)
     }
    }
};
</script>

<style>
.remark {
    position: absolute;

    right: 0;
}

.remarkbox {
    position: relative;

}

.mySearchInventory {
    float: left;
    width: calc(100% - 300px);
}
.right_aligh {
    top: -51px;
    right: 13px;
    position: relative;
    float: right;
}
.center{
    text-align:center;
}

.daterangepicker {
    min-width: 750px !important;
}
.vue-daterange-picker.v-text-field__slot {
    width: 96%;
    margin-bottom: 6px;
}
.daterangepicker .btn-primary, .daterangepicker .btn-success {
    background-color: #565656;
    color: #fff;
}
.v-data-footer {
    position: fixed;
    bottom: 0;
    width: calc( 100% - 342px);
    background: #fff;
        left: 0;

}
.v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 33px;
}
.cumulativebox2 {
    min-height: 324px;
    width: 100%;
}

.displbox{
     display:block;
    overflow:scroll;
    width:100%;

}
.reloadbuttons {
    margin: auto;
    float: none;
    display: block;
}
.sticky .v-slider.v-slider--horizontal.theme--light {
    background: #ffffffc9;
    box-shadow: 0 0 20px 0px #d2cecef2;
}
.tablemar table th, .tablemar table td {
    font-size:12px !important; 
        padding: 0 4px;
}
.sticky{
    position: fixed;
    top: 70px;
    width: 98%;
}
.materialbb .v-data-footer{
  width: 100% !important;
}
.imagespopup {
    position: fixed;
    top: 46px;
    left: 0;
    height: calc(100vh - 46px);
    background: #fff;
    /* overflow: auto; */
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: scroll;
}
</style>
