<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
          <v-col
        cols="12"
        md="6"
        class="proitems"
      >
      <v-radio-group
      v-model="projectsType"
      @change="setproejecttype"
      row
    >
      <v-radio
        :label="lang.Allprojects"
        value=" "
      ></v-radio>
      <v-radio
        :label="lang.Runningprojects"
        value="0"
      ></v-radio>
       <v-radio
        :label="lang.Completedprojects"
        value="1"
      ></v-radio>
    </v-radio-group>
         <v-row v-if="loopcout">
    <v-col
      v-for="(n, index) in projectdta"
      :key="index"
      :class="n.service_discontinue == '1'? 'active'+actt  :'notactive' "
      class="proejectitems"
      cols="6"
    >
        <v-img
         :src="n.thumbnail_name"
         aspect-ratio="1.5"
         class="grey lighten-2"
         v-if="actt == n.service_discontinue || both"

      > 
            <v-icon
      class="projectcamera"
      v-for="(c, indexOf ) in n.camm"
      @click="selectCamere(c,n,indexOf)"
      :key="c.id"
    >
      mdi-camera
    </v-icon>

      <v-icon
      class="projectcamera"
      
      v-for="(c, indexOf ) in n.camm1"
      
      @click="opentimelapsevideo(c,n,indexOf)"
      :key="c.id"
     >
      mdi-video-outline
    </v-icon>
    

    <span class="indicatetore" :class="n.active == '1'? 'active'  :'notactive' "></span>
       <span class="pnamej"> {{n.project_name }} </span>
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
     </v-col>
    </v-row>
     </v-col> 
      <v-col
        cols="12"
        md="6"
        class="maps"
        v-if="loopcout"
      >
         <gmap-map :center="center" :zoom="5" class="mapite">
      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
      </gmap-info-window>

      <gmap-marker :key="i" v-for="(m,i) in markers"  :position="m.position" :clickable="true" @click="toggleInfoWindow(m,i)"></gmap-marker>
    </gmap-map>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="messagealert"
      
      timeout="5000"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="messagealert = false"
        >
          {{lang.Close}}
        </v-btn>
      </template>
    </v-snackbar>

     <div
      v-if="videoo"
      class="dialog"
    >
      <v-icon
      class="closevideos"
      @click="videoo = false"
     >
      mdi-close
    </v-icon>
        <video class="videostyle"  controls="true" ref="custome_timelapsvideo" >
         </video> 
      
    </div>

   </div>
   
</template>
<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'
//import Sidebar from '../../components/SettingSidebar'
import loading from '../../assets/done.gif'
 import { locals } from "../../utils/locals";
export default {
  components: {
    Preloader
  },
  name: "globalDashboard",
  data() {
    return { 
       loading: loading,
       preloader: false,
       projectdta : [],
       coi: 0,
       loopcout : false,
       dats : [],
       center: { lat: 20.5937, lng:  78.9629},
       zoom: 5,
       markers: [],
       currentPlace: null,
        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,
        infoOptions: {
        	content: '',
            //optional: offset infowindow so it visually sits nicely on top of our marker
            pixelOffset: {
              width: 0,
              height: -35
            }
          },
          both : true,
          actt : '',
          projectsType : " ",
          vv : '',
          messagetype : '',
          message : '',
          messagealert : false,
          videoo : false, 
           Local :locals,  
        lang : {},
    }
  },
  mounted() {
      var selectleng = this.$cookies.get('currentLanguage');
      this.lang = locals[selectleng]; 

       let currentProject = JSON.parse(localStorage.getItem('currentProject'));
        this.userdata = JSON.parse(localStorage.getItem('userdata')); 
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }        
       this.projectid = currentProject.id;
    
       this.activelink = this.$route.path;
       this.current_cam = this.$store.state.currentCamera;
        this.initViewer();
        // this.geolocate();
  },
  watch: {
  '$store.state.currentCamera': function() {

     let currentProject = JSON.parse(localStorage.getItem('currentProject'));
     this.projectid = currentProject.id;
     this.current_cam = this.$store.state.currentCamera;
    
  }
  },
    computed: {
  
  }, 
  methods: {
     validate () {
        this.$refs.form.validate()
      },
    initViewer() {
     // console.log("init Viewer");
       this.preloader = false
       this.getprojects(); 
      
    },
    getprojects(){
        this.preloader = true;
         axios.get('/project/global_dashboard')
           .then(resp => {
                 console.log("global_dashboard", resp);
                // this.preloader = false;
                 this.dats = resp.data.success.projectdetail;
                 this.projectdta = resp.data.success.projectdetail;
                 this.coi = this.dats.length;
                
                var n = 0;
                   var sa = '';
                 this.dats.forEach( i => {
               
                if(i.active){
                  sa = '<a  href="/dashboard?p='+i.id+'" target="_blank"> Go To Dashboard </a>';
                }

                       this.markers[n] = { 'position': {
              'lat': parseFloat(i.lat),
              'lng': parseFloat(i.long),
            },
            'infoText' : '<p style="text-align:center"><strong>'+i.project_name+'</strong><br><strong>'+i.city+'</strong><br>'+sa+'</p>'
                       }

                //if(i.camm.length > 0){
                          this.getImageUrl(n);
                       // }else{
                         //this.projectdta[n].image_url = loading;
                       // }

                        n++;
                 });

                  console.log("data url",this.projectdta);
                  console.log('markers',this.markers);
              
           })
          .catch(err => {
            console.log("global_dashboard ",err)
           }) 
    },
    getLattersImage(camdata, n){

         //  this.preloader = false;
               console.log("lates images cam data",camdata);
        axios({ url: '/camera/'+camdata+'/latest_images', method: 'GET' })
          .then(resp => {
           // this.preloader = false;
             console.log("Lattest Image >>", resp);
             this.getImageUrl(resp.data.success[0].id, n);
           })
          .catch(err => {
            console.log("Error --> ", err);
           // console.log("Error --> ",this.preloader);
           // this.preloader = false;
          })
    },
    getImageUrl(n){
          

              if(this.coi == n+1){
             
               console.log("===>", this.coi, n+1);  

                  var v = this;
                
                   v.preloader = false;
                   v.loopcout = true;

                    v.projectdta.sort(function(a, b){
                      return b.active - a.active;
                    }); 
              }
  
          
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    geolocate: function() {

        navigator.geolocation.getCurrentPosition(position => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
        });

      

    },
    toggleInfoWindow: function(marker, idx) {
            this.infoWindowPos = marker.position;
            this.infoOptions.content = marker.infoText;

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
              this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
              this.infoWinOpen = true;
              this.currentMidx = idx;

            }
          },
         setproejecttype(){
               
               if(this.projectsType==' '){
                   this.both = true;
                   this.actt ==  ' '
               }else {
                this.both = false;         
                this.actt = this.projectsType;
         
               }
         },
        selectCamere(camera,project,index){
                 console.log(camera, project);
                 window.open("/dashboard?c="+camera.id+"&p="+project.id+"&i="+index, '_blank');
                // window.location.href();
                
         },
         opentimelapsevideo(cdata){

           this.videoo = true;
           
           console.log("passing camm1 data",cdata);

           var key = cdata.key;
           console.log("passing key",key);
         axios({ url: '/get_sign_url_by_key?key='+key+'&exp=60 minutes&project_id='+this.projectid, method: 'get' })
           .then(resp => {
                  console.log("Sign  url resonse", resp);
                  if(resp.data.success.info){
                   
                    this.vv =  resp.data.success.url;

                  // this.messagetype ='success'
                  // this.message = 'Video not found'
                  // this.messagealert = true 
                    this.$refs.custome_timelapsvideo.src = resp.data.success.url;
                    console.log(this.vv);
                  }else{
                  this.messagetype = 'error'
                  this.message = 'Video not found'
                  this.messagealert = true 
                   this.$refs.custome_timelapsvideo.src = resp.data.success.url;
                   this.videoo = false;
                  }
          })
          .catch(err => {
            console.log("Sign Url images",err)
            
           }) 
         }  
        }
};
</script>
<style>
.pnamej {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 7px;
    background: #000000a8;
    color: #f8bb03;
    font-weight: 500;
    left: 0;
}
.active0{
  display:none;
}
.proitems {
   height: calc(100vh - 55px);
   overflow:auto;
}
.mapite{
  height: calc(100vh - 55px);
  width: 100%;
}
.maps{
  height: calc(100vh - 55px);
}
.projectcamera {
    color: #ffbe01 !important;
    position: relative;
    top: 5px;
    left: 6px;
}
.indicatetore{
  display:block;
  position:absolute;
  top:10px;
  right:10px;
  border-radius:50%;
  height:12px;
  width:12px;
    box-shadow: 0 0 14px 1px #fff;
    
}
@-webkit-keyframes active-ANIMATION {
0%, 49% {

    box-shadow: 0 0 14px 1px rgb(4,82,4);
}
50%, 100% {
    box-shadow: 0 0 14px 1px rgb(11,255,11);
    
}
}

@-webkit-keyframes deacative-ANIMATION {
0%, 49% {
    box-shadow: 0 0 14px 1px rgb(255,113,30);
}
50%, 100% { 
    box-shadow: 0 0 14px 1px rgb(255,0,0);
}
}
.indicatetore.active{
  background: #55e255;
   -webkit-animation: active-ANIMATION 1s infinite; /* Safari 4+ */
  -moz-animation:    active-ANIMATION 1s infinite; /* Fx 5+ */
  -o-animation:      active-ANIMATION 1s infinite; /* Opera 12+ */
  animation:         active-ANIMATION 1s infinite; /* IE 10+, Fx 29+ */
}

.indicatetore.notactive{
  background:red;
   -webkit-animation: deacative-ANIMATION 1s infinite; /* Safari 4+ */
  -moz-animation:    deacative-ANIMATION 1s infinite; /* Fx 5+ */
  -o-animation:      deacative-ANIMATION 1s infinite; /* Opera 12+ */
  animation:         deacative-ANIMATION 1s infinite; /* IE 10+, Fx 29+ */
}

.opentvideo {
    color: #f5b903;
    position: absolute;
    left: 30%;
    top: 30%;
    z-index: 9999;
}
.videostyle {
    width: 100%;
    height: 85.6vh;
}
.dialog {
    position: fixed;
    top: 0;
    left: 0;
    background: #000000b0;
    padding: 4% 10%;
    width: 100%;
}
.closevideos {
    position: absolute;
    right: 3px;
    float: right;
    top: 28px;
    color: #fff !important;
    z-index: 999;
}

</style>

